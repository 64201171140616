// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-golang-struct-cheat-sheet-js": () => import("./../../../src/pages/golang/struct-cheat-sheet.js" /* webpackChunkName: "component---src-pages-golang-struct-cheat-sheet-js" */),
  "component---src-pages-google-ad-manager-test-js": () => import("./../../../src/pages/google-ad-manager/test.js" /* webpackChunkName: "component---src-pages-google-ad-manager-test-js" */),
  "component---src-pages-google-cloud-functions-cheat-sheet-js": () => import("./../../../src/pages/google-cloud/functions-cheat-sheet.js" /* webpackChunkName: "component---src-pages-google-cloud-functions-cheat-sheet-js" */),
  "component---src-pages-google-cloud-storage-cheat-sheet-js": () => import("./../../../src/pages/google-cloud/storage-cheat-sheet.js" /* webpackChunkName: "component---src-pages-google-cloud-storage-cheat-sheet-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-seo-adding-site-to-google-js": () => import("./../../../src/pages/seo/adding-site-to-google.js" /* webpackChunkName: "component---src-pages-seo-adding-site-to-google-js" */)
}

